.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

@media only screen and (max-width: 1250px) {
  .App {
    display: flex;
    flex-direction: column;
  }
}
